<template>
	<v-sheet flat>	
		<v-toolbar flat>
			<v-tabs v-model="tab" color="grey">
				<v-tab>{{mwtr.DepartmentNavItem}}</v-tab>
			</v-tabs>
			<v-spacer></v-spacer>
			
		</v-toolbar>

		<v-tabs-items v-model="tab">
			<v-tab-item>
				<GeneralForm :id="id" collection="departments" :data="department">
					<template v-slot:formContent>
						<GeneralField 
						v-for="field in fields" 
						:data="department[field.value]" 
						:key="field.value"
						@update="(value) => department[field.value] = value" 
						v-bind="field"></GeneralField>
						<v-select v-model="department.section" :items="items"></v-select>
					</template>
				</GeneralForm>
			</v-tab-item>
			
		</v-tabs-items>
	</v-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import GeneralForm from "@/components/admin/forms/GeneralForm"
	import GeneralField from "@/components/admin/forms/fields/GeneralField"

	export default {
		name: "EditDepartment", 
		props: {
			id: {}
		},
		data: () => {
			return {
				tab: 0
			}
		},
		computed: {
			...Vuex.mapState({
				sections: state => state.sections
			}),
			items() {
				return this.mwutilities.itemsArray( this.sections )
			},
			fields(){
				return this.mwsettings.getFields("Department");
			},
			department(){
				var department = this.$store.state.departments[this.id]
				return department || this.mwsettings.getEmpty("Department");
			}, 
			section(){
				if( this.id ){
					return this.department.section
				}
				return false;
			}, 
		},
		components: {
			GeneralForm, 
			GeneralField, 
		},
		created(){
			if( this.id ){			
				this.$store.dispatch("departments/fetchOne", this.id)
			}
			this.$store.dispatch("departments/fetchOne", this.id)
			this.$store.dispatch("sections/fetchAll",)
		},
		destroyed(){
			this.$store.dispatch("detachOne", this.id)
		},

	}
// </script>"